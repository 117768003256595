import { useCallback, useEffect, useState } from 'react';
import Button from '../Button/button';
import styles from './folderPermissionsEditComponent.module.css';
import mainStyles from '../../pages/main.module.css';
import { Folder, RoleListItem } from '../../services/http/folder.api';
import { UserGroup } from '../../services/http/userGroup.api';
import { User } from '../../services/http/user.api';
import { useSearchUserApi } from '../../hooks/api/user.hook';
import { useSearchUserGroupApi } from '../../hooks/api/userGroup.hook';
import { useGetAuthorityApi } from '../../hooks/api/folderPermissions.hook';
import { useFolderRoles } from '../../hooks/api/folder.hook';

/**
 * フォルダ権限リスト（ユーザー）のプロップスインターフェース
 */
export interface FolderControlListRole {
  controlType: number,
  controlTargetId: string,
  roleId: number,
  userName?: string,
}

/**
 * フォルダ権限リスト（ユーザーグループ）のプロップスインターフェース
 */
export interface FolderGroupUserControlListRole {
  controlType: number,
  controlTargetId: number,
  roleId: number,
  userName?: string,
}

/**
 * フォルダ権限の編集コンポーネントのプロップスインターフェース
 */
export interface FolderPermissionEditComponentProps {
  /** 使用されるフォルダー */
  folder: Folder | null
  /** ユーザー管理権限リストの変更時に実行されるハンドラー */
  handleSavedUserControlListChanged: (list: FolderControlListRole[])=>void
  /** ユーザーグループ管理権限リストの変更時に実行されるハンドラー */
  handleSavedUserGroupControlListChanged: (list: FolderGroupUserControlListRole[])=>void
  /** フォルダーIDの取得時に実行されるハンドラー */
  handleFolderIdFetched: (id?: number) => void
  /** 組織変更履歴ID */
  organizationChangeHistoryId?: number
  /** 強制更新フラグ */
  forceUpdateFlag?: number
}

/**
 * フォルダ権限の編集コンポーネント
 */
export default function FolderPermissionEditComponent({
  folder,
  handleSavedUserControlListChanged: handleSavedUserControlListChange,
  handleSavedUserGroupControlListChanged: handleSavedUserGroupControlList,
  handleFolderIdFetched,
  organizationChangeHistoryId,
  forceUpdateFlag,
}: FolderPermissionEditComponentProps) {
  // States
  const [usersState, setUsersState] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [userControlList, setUserControlList] = useState<FolderControlListRole[]>([]);
  const [userGroupControlList, setUserGroupControlList] = useState<FolderGroupUserControlListRole[]>([]);
  const [userGroupsState, setUserGroupsState] = useState<UserGroup[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [roleOptions, setRoleOptions] = useState<RoleListItem[]>([]);

  // APIs
  const { request: searchUserApiRequest } = useSearchUserApi();
  const { request: userGroupSearchApiRequest } = useSearchUserGroupApi();
  const { request: getFolderAuthorityRequest } = useGetAuthorityApi();
  const { request: getFolderRolesRequest } = useFolderRoles();

  useEffect(() => {
    handleSavedUserControlListChange(userControlList);
    handleSavedUserGroupControlList(userGroupControlList);
  }, [userControlList, userGroupControlList, handleSavedUserControlListChange, handleSavedUserGroupControlList]);

  // Effects
  useEffect(() => {
    getFolderRolesRequest({}).then((res) => {
      setRoleOptions(res);
    });
    getFolderAuthorityRequest({ folderId: folder?.id, organizationChangeHistoryId }).then((response) => {
      searchUserApiRequest({ deleteFlg: 0 }).then((res) => {
        const newUserList : User[] = [];
        const newUserControlList: FolderControlListRole[] = [];
        res.forEach((user) => {
          if (response.some((item) => `${item.controlTargetId}` === user.id && item.controlType === 2)) {
            newUserControlList.push({
              controlType: 2,
              controlTargetId: user.id,
              roleId: response.find((userGroup) => `${userGroup.controlTargetId}` === user.id)?.roleId || -1,
              userName: user.userName,
            });
          } else {
            newUserList.push(user);
          }
        });
        setUsers(newUserList);
        setUserControlList(newUserControlList);
        setUsersState(res);
      });
      userGroupSearchApiRequest({ id: organizationChangeHistoryId }).then((res) => {
        const newUserGroup: UserGroup[] = [];
        const newUserGroupControlList: FolderGroupUserControlListRole[] = [];
        res.forEach((group) => {
          const normalizedId = String(group.id);
          if (response.some((userGroup) => userGroup.controlTargetId === normalizedId && userGroup.controlType === 1)) {
            newUserGroupControlList.push({
              controlType: 1,
              controlTargetId: group.id,
              roleId: response.find((userGroup) => userGroup.controlTargetId === normalizedId)?.roleId || -1,
              userName: group.name,
            });
          } else {
            newUserGroup.push(group);
          }
        });
        setUserGroups(newUserGroup);
        setUserGroupControlList(newUserGroupControlList);
        setUserGroupsState(res);
      });
    });
    handleFolderIdFetched(folder?.id);
  }, [folder?.id, organizationChangeHistoryId, userGroupSearchApiRequest, searchUserApiRequest, getFolderAuthorityRequest, handleFolderIdFetched, getFolderRolesRequest, forceUpdateFlag]);

  // Methods
  // handle user list methods
  const handleSelectedUser = useCallback((userId: string) => {
    const newUsers = users.filter((u) => u.id !== userId);
    setUsers(newUsers);
    const user = users.find((u) => u.id === userId);
    setUserControlList([...userControlList, {
      controlType: 2,
      controlTargetId: userId,
      roleId: roleOptions[0].roleId,
      userName: user?.userName,
    }]);
  }, [users, userControlList, roleOptions]);

  const handleSelectedUserRole = useCallback((role: number, userId: string) => {
    const newControlList = userControlList.map((u) => { if (u.controlTargetId === userId) { return { ...u, roleId: role }; } return u; });
    setUserControlList(newControlList);
  }, [userControlList]);

  const handleDeleteUserRow = useCallback((userId: string) => {
    const newUserControlList = userControlList.filter((u) => u.controlTargetId !== userId);
    setUserControlList(newUserControlList);
    const user = usersState.find((u) => u.id === userId);
    if (user) setUsers([...users, user]);
  }, [userControlList, users, usersState]);

  // handle user group list methods
  const handleSelectedUserGroup = useCallback((userGroupId: number) => {
    const newUserGroups = userGroups.filter((u) => u.id !== userGroupId);
    setUserGroups(newUserGroups);
    const userGroup = userGroups.find((u) => u.id === userGroupId);
    setUserGroupControlList([...userGroupControlList, {
      controlType: 1,
      controlTargetId: userGroupId,
      roleId: roleOptions[0].roleId,
      userName: userGroup?.name,
    }]);
  }, [userGroups, userGroupControlList, roleOptions]);

  const handleSelectedUserGroupRole = useCallback((role: number, userGroupId: number) => {
    const newControlList = userGroupControlList.map((u) => { if (u.controlTargetId === userGroupId) { return { ...u, roleId: role }; } return u; });
    setUserGroupControlList(newControlList);
  }, [userGroupControlList]);

  const handleDeleteUserGroupRow = useCallback((userGroupId: number) => {
    const newUserGroupControlList = userGroupControlList.filter((u) => u.controlTargetId !== userGroupId);
    setUserGroupControlList(newUserGroupControlList);
    const userGroup = userGroupsState.find((u) => u.id === userGroupId);
    if (userGroup) setUserGroups([...userGroups, userGroup]);
  }, [userGroupControlList, userGroups, userGroupsState]);

  return (
    <div className={styles.body}>
      <div className={mainStyles['mb-20px']}>
        {folder?.name}
        {' '}
        {' '}
        フォルダ権限
      </div>
      <div className={styles.bodyMain}>

        {/* user group */}
        <div>
          <div className={mainStyles['mb-10']}>
            <img src="/images/Dropdown-box-arrow.svg" alt="triangle" className={mainStyles['mr-1']} />
            {' '}
            ユーザーグループ
            {' '}
          </div>
          { userGroupControlList.map((u) => (
            <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')} key={u.controlTargetId}>
              <div title={u.userName} className={styles['div-table']}>
                {
                      u.userName
                    }
              </div>
              <select
                className={styles['select-table']}
                onChange={(e) => handleSelectedUserGroupRole(parseInt(e.target.value, 10), u.controlTargetId)}
                value={u.roleId}
              >
                {roleOptions.map((r) => (
                  <option key={r.roleId} title={r.roleName} value={r.roleId}>{r.controlList.map((item) => item.controlName).join('')}</option>
                ))}
              </select>
              <Button onClick={() => handleDeleteUserGroupRow(u.controlTargetId)}>
                <img src="/images/Icon-feather-trash-2.svg" alt="" className={styles.deleteImg} />
              </Button>
            </div>
          )) }

          <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
            {userGroups.length === 0 ? (
              <select
                disabled
                style={{ borderBottom: 'var(--defaultBorder)' }}
                className={[mainStyles['border-bottom'], styles['background-gray'], styles['select-table-disabled'], styles['select-table-last']].join(' ')}
              />
            ) : (
              <select
                style={{ borderBottom: 'var(--defaultBorder)' }}
                className={[mainStyles['border-bottom'], styles['background-gray'], styles['select-table'], styles['select-table-last']].join(' ')}
                onChange={(e) => handleSelectedUserGroup(parseInt(e.target.value, 10))}
              >
                {userGroups.length > 0 && (
                <option title="ユーザーグループを選択" value="...">ユーザーグループを選択</option>
                )}
                {userGroups.map((user) => (

                  <option key={user.id} value={user.id} title={user.name}>
                    {user.name}
                  </option>
                ))}
              </select>
            )}
            <div style={{ borderBottom: 'var(--defaultBorder)' }} className={[mainStyles['border-bottom'], styles['div-table'], styles['div-table-last']].join(' ')} />
          </div>
        </div>

        {/* user */}
        <div style={{ marginLeft: '80px', paddingRight: 20 }}>
          <div className={mainStyles['mb-10']}>
            <img src="/images/Dropdown-box-arrow.svg" alt="triangle" className={mainStyles['mr-1']} />
            {' '}
            ユーザー
            {' '}
          </div>
          { userControlList.map((u) => (
            <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')} key={u.controlTargetId}>
              <div title={u.userName} className={styles['div-table']}>
                {
                      u.userName
                    }
              </div>
              <select
                className={styles['select-table']}
                onChange={(e) => handleSelectedUserRole(parseInt(e.target.value, 10), u.controlTargetId)}
                value={u.roleId}
              >
                {roleOptions.map((r) => (
                  <option
                    key={r.roleId}
                    title={r.roleName}
                    className={styles['select-option']}
                    value={r.roleId}
                  >
                    {r.controlList.map((item) => item.controlName).join('')}
                  </option>
                ))}
              </select>
              <Button onClick={() => handleDeleteUserRow(u.controlTargetId)}>
                <img src="/images/Icon-feather-trash-2.svg" alt="" className={styles.deleteImg} />
              </Button>
            </div>
          )) }

          <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
            {users.length === 0 ? (
              <select
                style={{ borderBottom: 'var(--defaultBorder)' }}
                className={[mainStyles['border-bottom'], styles['background-gray'], styles['select-table-disabled'], styles['select-table-last']].join(' ')}
                disabled
              />
            ) : (
              <select
                style={{ borderBottom: 'var(--defaultBorder)' }}
                className={[mainStyles['border-bottom'], styles['background-gray'], styles['select-table'], styles['select-table-last']].join(' ')}
                onChange={(e) => handleSelectedUser(e.target.value)}
              >
                {users.length > 0 && (
                <option title="ユーザーを選択" value="...">ユーザーを選択</option>
                )}
                {users.map((user) => (
                  <option key={user.id} value={user.id} title={user.userName}>
                    {user.userName}
                  </option>
                ))}
              </select>
            )}
            <div style={{ borderBottom: 'var(--defaultBorder)' }} className={[mainStyles['border-bottom'], styles['div-table'], styles['div-table-last']].join(' ')} />
          </div>
        </div>
      </div>
    </div>
  );
}
