import { v1 as uuid } from 'uuid';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './docRegisterScreenPage.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import { DocumentRegisterForm, DocumentsFromCsv } from '../../services/http/documents.api';
import DocRegisterFormList from './DocRegisterFormList';
import DocRegisterForm from './DocRegisterForm';
import DocRegisterPdfPreview from './DocRegisterPdfPreview';
import routes from '../../utils/routes';
import { useFolderSearch } from '../../hooks/api/folder.hook';
import { Folder } from '../../services/http/folder.api';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { Tag } from '../../services/http/tag.api';
import TagFormat from '../../utils/tagFormat';
import { useAuthContext } from '../../store/auth.store';
import ControlCode from '../../utils/controlCode';
import { PdfFileText } from '../csvImportScreenPage/csvImportScreenTypes';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * PDFファイルのインターフェイス
 */
export interface PdfFile {
  pdfFileName: string;
  pdfFile: File;
}

/**
 * 文書登録ページのコンポーネント
 */
function Component() {
  usePageTitle('文書登録');
  const location = useLocation();
  const navigate = useNavigate();
  const folderId = useMemo(() => (location.state as { folderId: number | undefined })?.folderId, [location.state]);
  const documentsFromCsv = useMemo(() => (location.state as { form: DocumentsFromCsv })?.form, [location.state]);
  const { hasFolderPermission } = useAuthContext();
  const pdfList = useMemo(() => (location.state as { pdfList: PdfFileText[] })?.pdfList ?? [], [location.state]);

  const initialForm = useMemo(() => ({
    internalId: uuid(),
    documentName: '',
    documentTypeId: 0,
    documentTypeName: '',
    barcodePrinting: '',
    documentClassification: 0,
    folderId,
    tagList: [],
    relationalDocumentList: [],
    attachmentFileList: [],
  }), [folderId]);

  // States
  const [forms, setForms] = useState<DocumentRegisterForm[]>([initialForm]);
  const [form, setForm] = useState<DocumentRegisterForm>(forms[0]);
  const [folder, setFolder] = useState<Folder>({
    reservedFlg: false, name: '', id: 0, authorityList: [],
  });
  const [selectOptionFromCsvPage, setSelectOptionFromCsvPage] = useState<any>();
  const [selectedFormIndex, setSelectedFormIndex] = useState<number>(0);
  const [documentIsSelected, setDocumentIsSelected] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  // Hooks
  const folderSearch = useFolderSearch();
  const { request: createLog } = useCreateLogApi();

  // Methods
  const onAddForm = async (preForms: DocumentRegisterForm[]) => {
    setDocumentIsSelected(true);
    setSelectedFormIndex(forms.length);
    const updatedPreForms = preForms.map((preForm) => ({ ...preForm, folderId }));
    await setForms([...forms, ...updatedPreForms]);
    setForm(updatedPreForms[0]);
  };

  const onUpdateForm = useCallback((updatedForm: DocumentRegisterForm) => {
    if (updatedForm.electronicFlg === 1) {
      // eslint-disable-next-line no-param-reassign
      updatedForm.paperFlg = 0;
    }
    setForm(() => {
      setForms(forms.map((f) => (f.internalId === updatedForm.internalId ? updatedForm : f)));
      return updatedForm;
    });
  }, [forms]);

  const onSelectedForm = useCallback((document: DocumentRegisterForm) => {
    const selectedFormIdx = forms.indexOf(document);
    setForm(document);
    setSelectedFormIndex(selectedFormIdx);
    setDocumentIsSelected(true);
  }, [forms]);

  const updateSelectedFormIndex = useCallback((removedFormIndex: number) => {
    if (removedFormIndex < selectedFormIndex) setSelectedFormIndex(selectedFormIndex - 1);
  }, [selectedFormIndex]);

  const updateDocumentSelected = useCallback((removedFormIndex: number) => {
    if (removedFormIndex === selectedFormIndex) {
      setDocumentIsSelected(false);
    }
  }, [selectedFormIndex]);

  const onRemoveForm = useCallback((removeForm: DocumentRegisterForm) => {
    setForms(forms.filter((item) => item !== removeForm));
    const removedFormIndex = forms.indexOf(removeForm);
    updateSelectedFormIndex(removedFormIndex);
    updateDocumentSelected(removedFormIndex);
  }, [updateSelectedFormIndex, forms, updateDocumentSelected]);

  const validateTagsTypeDate = (tags: Tag[]) => {
    const tagList: Tag[] = [];
    tags.forEach((tag: Tag) => {
      if (tag.format === TagFormat.DATE) {
        const regex = /^\d{4}\/\d{2}\/\d{2}$/;
        if (regex.test(tag.value.trim())) {
          tagList.push(tag);
        } else {
          tagList.push({ ...tag, value: '' });
        }
      } else {
        const validatedTag = TagFormat.validate(tag.format, tag.value);
        tagList.push({ ...tag, value: validatedTag });
      }
    });
    return tagList;
  };

  const setDocumentsFromCsvToForms = useCallback(async (pdfFiles: PdfFile[]) => {
    if (documentsFromCsv) {
      setSelectOptionFromCsvPage(documentsFromCsv.documentClassificationOption);
      const { docsToRegister } = documentsFromCsv;
      const newForms: DocumentRegisterForm[] = [];
      docsToRegister.forEach((doc) => {
        const pdfFileObject = pdfFiles.find((file) => file.pdfFileName === doc.pdfFileName);
        const newForm:DocumentRegisterForm = {
          internalId: uuid(),
          documentName: doc.docName,
          documentTypeId: documentsFromCsv?.documentClassificationOption?.type.documentTypeId as number,
          documentTypeName: documentsFromCsv?.documentClassificationOption?.type.documentTypeName,
          barcodePrinting: doc.barcodePrinting ?? '',
          documentClassification: documentsFromCsv.documentClassificationOption?.documentClassification.id,
          documentFileName: doc.pdfFileName,
          documentFile: pdfFileObject?.pdfFile,
          paperFlg: Number(doc.paperFlg) ? 1 : 0,
          electronicFlg: Number(doc.electronicFlg) ? 1 : 0,
          memo: doc.memo ? doc.memo : '',
          folderId,
          tagList: doc.tags ? validateTagsTypeDate(doc.tags) : [],
          relationalDocumentList: [],
          attachmentFileList: [],
        };
        newForms.push(newForm);
      });
      await setForms([...newForms]);
      setForm(newForms[0]);
      setSelectedFormIndex(0);
    }
  }, [documentsFromCsv, folderId]);

  const convertFilesAndSetForm = async () => {
    const pdfFiles: PdfFile[] = [];
    for (let i = 0; i < pdfList.length; i += 1) {
      pdfFiles.push({ pdfFileName: pdfList[i].pdfFileName, pdfFile: pdfList[i].pdfFile });
    }
    setDocumentsFromCsvToForms(pdfFiles);
  };

  // Effects
  useEffectOnce(() => {
    if (folderId === undefined) {
      navigate(routes.main);
      return;
    }
    folderSearch.request({}).then((res) => {
      const foundFolder = res.find((item) => item.id === folderId);
      if (!foundFolder || !hasFolderPermission(foundFolder, ControlCode.Write)) {
        navigate(routes.main);
      } else setFolder(foundFolder);
    });
  });

  useEffectOnce(() => {
    createLog(LogFormName.DocRegisterScreen, LogControlName.Show);

    setForm(initialForm);
    setSelectedFormIndex(0);
    if (documentsFromCsv) {
      convertFilesAndSetForm();
    }
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainFrame}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles.mainFrameBody}>
            <DocRegisterFormList
              updatedForm={form}
              forms={forms}
              onFormsRejected={(e) => {
                setForms(e);
                setForm(e[0]);
              }}
              onAddForm={onAddForm}
              onRemoveForm={onRemoveForm}
              onFormSelected={onSelectedForm}
              isRequesting={isRequesting}
            />
            <div className={styles.mainFrameRightSection}>
              <div className={styles.mainFrameRightSectionCols}>
                <DocRegisterForm
                  documentIsSelected={documentIsSelected}
                  selectOptionFromCsvPage={selectOptionFromCsvPage}
                  form={form}
                  folder={folder}
                  onChangeForm={onUpdateForm}
                  setIsRequesting={setIsRequesting}
                />
                <DocRegisterPdfPreview form={form} />
              </div>
            </div>
          </div>
        </div>
        )}
    />
  );
}

export default Component;
