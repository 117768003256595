import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { RowNode } from 'ag-grid-enterprise';
import { DocumentWarehousing } from '../../services/http/documentWarehousing.api';
import styles from './exportLabelComponent.module.css';
import mainStyles from '../main.module.css';
import { useExportLabelApi } from '../../hooks/api/exportLabel.hook';
import BaseTable from '../../components/BaseTable';
import LoadingOverlay from '../../components/LoadingOverlay';
import Formatter from '../../utils/formatters';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import routes from '../../utils/routes';
import FileUtils from '../../utils/file.utils';
import AGUtils from '../../utils/ag-grid.utils';
import ControlCode from '../../utils/controlCode';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import RadioGroupInput from '../../components/RadioGroupInput';
import SelectInput from '../../components/SelectInput';
import BaseText from '../../components/BaseText/BaseText';
import { STATUS_MESSAGES } from '../../utils/messages';

/**
 * モーダルドキュメントファイルサイズコンポーネント
 *
 * @param props - data - 文書倉庫データ
 */
export function ModalDocumentFileSize(props: { data: DocumentWarehousing }) {
  const { data } = props;

  const fileSize = data.documentFileSize;
  if (fileSize == null) {
    return <div />;
  }

  return <div>{Formatter.byteSizeDescription(fileSize)}</div>;
}

/**
 * ナンバーセル コンポーネント
 *
 * @param props - props.rowIndex - 行インデックス番号
 */
export function NoCell(props: { rowIndex: number }) {
  const { rowIndex } = props;
  return <span>{ rowIndex + 1 }</span>;
}

/**
 * タグのリストを表示するセルをレンダリングする関数
 *
 * @param props - data - 文書倉庫データ
 */
export function TagsCell(props: { data: DocumentWarehousing }) {
  const { data } = props;
  return <div>{data.tagList.map((d) => d.tagLabel).join(',')}</div>;
}

/**
 * ラベル発行コンポーネントのプロップスインターフェース
 */
export interface ExportLabelProps {
  /** 文書倉庫データ */
  data: DocumentWarehousing[] | null
  /** モーダル表示フラグ */
  display: boolean
  /** モーダル表示フラグをセットする関数 */
  setModalDisplay: (display:boolean) => void
  /** テーブル名 */
  tableName: string,
  /** メイン画面に戻るかどうか */
  returnToMain?: boolean
  /** エクスポート元 */
  exportFrom?: string
  /** ソートされたIDリスト */
  sortedIdList?: number[]
}

enum ExportLabelTypes {
  ONE_LABEL = '1',
  TWO_LABELS = '2',
  TEN_LABELS = '10',
}

/**
 * ラベル発行コンポーネント
 */
function ExportLabelComponent({
  data, display, setModalDisplay, tableName, returnToMain, exportFrom, sortedIdList,
}: ExportLabelProps) {
  const { loading: exportLabelloading, request: exportLabelApiRequest } = useExportLabelApi();
  const { request: createLog } = useCreateLogApi();

  const openMessageModal = useMessageModal();
  const navigate = useNavigate();

  const [labelPrintType, setLabelPrintType] = useState<ExportLabelTypes>(ExportLabelTypes.ONE_LABEL);
  const [printStartPosition, setPrintStartPosition] = useState(1);
  const [noRowText, setNoRowText] = useState<string>(STATUS_MESSAGES.NO_DATA);

  const gridRef = useRef<AgGridReact<DocumentWarehousing>>(null);

  const onClose = useCallback(() => {
    if (returnToMain) {
      navigate(routes.main);
    }
    setModalDisplay(false);
  }, [setModalDisplay]);

  const colDefModal = useMemo((): ColDef<DocumentWarehousing>[] => [
    AGUtils.colAutoIncrement('no', 'No', 0),
    AGUtils.colDefault('ccControlNumber', '文書ID'),
    {
      valueGetter: (params) => (exportFrom === ControlCode.Receiving ? ControlCode.describe(ControlCode.Issuing) : params?.node?.data?.itemCode),
      headerName: '保管品バーコード',
      resizable: true,
      width: 160,
    },
    AGUtils.colDefault('documentName', '文書名', 250, false),
    AGUtils.colDefault('documentTypeName', '文書種類', 130, false),
    AGUtils.colDefault('documentFileName', 'ファイル名', 170, false),
    AGUtils.colFileSize('documentFileSize', 'サイズ', false),
    AGUtils.colUsername('registUser', '登録者', false),
    AGUtils.colDate('registDate', '登録日時', false),
    AGUtils.colUsername('updateUser', '更新者', false),
    AGUtils.colDate('updateDate', '更新日時', false),
    AGUtils.colDefault('barcodePrinting', 'バーコード印字欄', 150, false),
    {
      field: 'tagList',
      headerName: 'タグ',
      resizable: true,
      minWidth: 200,
      flex: 1,
      cellRenderer: TagsCell,
    },
  ], []);

  const onExportClick = useCallback(async () => {
    const list: { documentId: number }[] = [];
    gridRef.current?.api?.forEachNodeAfterFilterAndSort((node) => {
      const documentId = node.data?.documentId;
      if (documentId != null) {
        list.push({ documentId });
      }
    });
    try {
      await exportLabelApiRequest({
        documentIdList: list,
        labelPrintType: Number(labelPrintType),
        printStartPosition,
      }).then((d) => {
        const b = FileUtils.fromBase64(`${d.documentLabelFile}`, '保管品ラベル', { type: 'application/pdf' });
        const url = window.URL.createObjectURL(b);
        const a = document.createElement('a');
        a.setAttribute('target', '_blank');
        a.href = url;
        a.click();

        setTimeout(() => {
          const downloadTag = document.createElement('a');
          downloadTag.setAttribute('download', '保管品ラベル');
          downloadTag.href = url;
          downloadTag.click();
          window.URL.revokeObjectURL(url);
        }, 500);

        if (returnToMain) {
          navigate(routes.main);
        }
      });
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [exportLabelApiRequest, navigate, openMessageModal, returnToMain, labelPrintType, printStartPosition]);

  const handleSort = (nodes: RowNode<any>[]) => {
    nodes.sort((a, b) => {
      const aIndex = sortedIdList?.indexOf(a.data.documentId);
      const bIndex = sortedIdList?.indexOf(b.data.documentId);
      if (aIndex == null || bIndex == null) {
        return 0;
      }
      return aIndex - bIndex;
    });
  };

  const labelDropdownOptions = Array.from({ length: 10 }, (_, i) => ({
    value: i + 1,
    text: String(i + 1),
  }));

  useEffectOnce(() => {
    createLog(LogFormName.ExportLabel, LogControlName.Show);
  });

  useEffect(() => {
    if (data === null) {
      setNoRowText(STATUS_MESSAGES.LOADING_DATA);
    } else if (data.length === 0) {
      setNoRowText(STATUS_MESSAGES.NO_DATA);
    }
  }, [data]);

  if (!display) {
    return (<div />);
  }
  return (
    <div className={styles.modalContainer} role="presentation">
      <div className={styles.modal} role="presentation" onClick={(e) => e.stopPropagation()}>
        <div className={styles.labelTypeContainer}>
          <BaseText bold className={mainStyles['mb-10']}> 保管品ラベル発行タイプ </BaseText>
          <div className={styles.labelTypeRadioContainer}>
            <div className={styles.radioContainer}>
              <RadioGroupInput className={mainStyles['mb-10']} value={labelPrintType} onChange={(value) => setLabelPrintType(value)}>
                <RadioGroupInput.RadioInput value={ExportLabelTypes.ONE_LABEL} style={{ marginLeft: 20 }}>
                  1面ラベル
                </RadioGroupInput.RadioInput>
                <RadioGroupInput.RadioInput value={ExportLabelTypes.TWO_LABELS}>
                  2面ラベル
                </RadioGroupInput.RadioInput>
                <RadioGroupInput.RadioInput value={ExportLabelTypes.TEN_LABELS}>
                  10面ラベル
                </RadioGroupInput.RadioInput>
              </RadioGroupInput>
              {labelPrintType === ExportLabelTypes.TEN_LABELS && (
              <>
                <div className={mainStyles['d-flex']}>
                  <BaseText bold className={mainStyles['mb-10']}>印字開始番号 </BaseText>
                  {' '}
                  <span className={` ${mainStyles['text-danger']} }`} style={{ marginLeft: '15px' }}>※</span>
                </div>
                <SelectInput
                  value={printStartPosition}
                  options={labelDropdownOptions}
                  onChange={(value) => setPrintStartPosition(value)}
                  style={{ width: '150px' }}
                  menuStyle={{ minWidth: '150px', left: '60%' }}
                />
              </>
              )}
            </div>
            {labelPrintType === ExportLabelTypes.TEN_LABELS && (
            <div>
              <BaseText size="sm" color="var(--danger)">※ 印字開始番号と印刷位置の対応 </BaseText>
              <div className={styles.gridContainer}>
                {Array.from({ length: 10 }, (_, i) => (
                  <div key={i} className={styles.numberBox}>
                    <BaseText>
                      {i + 1}
                    </BaseText>
                  </div>
                ))}
              </div>

            </div>
            )}
          </div>
        </div>
        <div className={styles.warningMessageContainer}>
          <div>
            遷移元画面の並び順で出力されます。
          </div>
          <div>
            出力順を変更したい場合は前の画面で並び順を変更してください。
          </div>
        </div>

        <div className={styles['modal-body']}>
          <div className={styles['modal-list']}>
            <BaseTable<DocumentWarehousing>
              tableRef={gridRef}
              formName={tableName}
              rowData={data}
              columnDefs={colDefModal}
              sortFunction={handleSort}
              noRowsText={noRowText}
              createLogOnDownloadTableData={LogFormName.ExportLabel}
            />
          </div>
          <footer>
            <button type="button" className={[mainStyles.button, mainStyles['btn-gray']].join(' ')} onClick={onClose}>閉じる</button>
            <button type="button" className={[mainStyles.button, mainStyles['btn-primary']].join(' ')} onClick={() => onExportClick()}>実行</button>
          </footer>
        </div>
      </div>
      {exportLabelloading && <LoadingOverlay />}
    </div>
  );
}

export default ExportLabelComponent;
