import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './resetPasswordPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import Input from '../../components/Input/input';
import { useSendEmailApi } from '../../hooks/api/password.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';

/**
 * パスワードリセットページ
 */
function ResetPasswordPage() {
  // Context States
  const navigator = useNavigate();
  const openMessageModal = useMessageModal();

  // States
  const [tenantCode, setTenantCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  // Custom Hooks
  const sendEmailApi = useSendEmailApi();

  // Aux states
  const validForm = !!tenantCode && !!email;

  usePageTitle('パスワードリセット');

  const sendForm = async () => {
    try {
      Validator.validateTenantCode(tenantCode);
      Validator.validateEmail(email);
      await sendEmailApi.request({ tenantCode, email });
      navigator(routes.resetPasswordSent);
    } catch (e) {
      setError((e as Error).message);
      openMessageModal((e as ApiError)?.message);
    }
  };

  const handleCancel = () => {
    navigator(routes.login);
  };

  return (
    <CustomFrame
      title="パスワードリセット"
      desc={(
        <div className={styles.descDesc}>
          <div>
            <p className={styles.firstParagraph}>CLOUD CABINETでご利用のテナントコードおよびメールアドレスを入力してください。</p>
            <p>「上記アドレスに送信する」をクリックすると、入力したメールアドレス宛にパスワード再設定用メールが送信されます。</p>
          </div>

          <div className={styles.form}>
            <div className={styles.formItem}>
              <div className={styles.formItemField}>テナントコード</div>
              <div><Input type="text" value={tenantCode} onChange={setTenantCode} /></div>
            </div>

            <div className={styles.formItem}>
              <div className={styles.formItemField}>メールアドレス</div>
              <div><Input type="text" value={email} onChange={setEmail} /></div>
            </div>
          </div>
          <div className={styles.errorText}>{error}</div>
          {sendEmailApi.loading && <LoadingOverlay />}
        </div>
      )}
      body={(
        <div className={styles.buttonContainer}>
          <div><Button content="ログイン画面に戻る" onClick={handleCancel} /></div>
          <div><Button content="上記アドレスに送信する" onClick={sendForm} disabled={!validForm} /></div>
        </div>
      )}
    />
  );
}

export default ResetPasswordPage;
