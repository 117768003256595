import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './disposalRequestScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import { DocumentWarehousing, TagList } from '../../services/http/documentWarehousing.api';
import { DisposeReserveForm } from '../../services/http/reserve.api';
import { CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import BaseTable from '../../components/BaseTable';
import SelectInput from '../../components/SelectInput';
import Input from '../../components/Input/input';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import { useDisposeReserveApi } from '../../hooks/api/reserve.hook';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 倉庫リクエスト用のプロップス
 */
export interface WareHousingRequestProps {
  /** クリック時に呼び出される関数 */
  onDeleteClick: (documentWarehousing: DocumentWarehousing) => void;
  /** クリック時に関数に渡すデータ */
  data: DocumentWarehousing;
}

/**
 * テーブルセルレンダラーコンポーネント
 */
export function TableBtnCellRender({ data, onDeleteClick }: WareHousingRequestProps) {
  return (
    <div>
      <button type="button" className={[mainStyles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onDeleteClick(data)}>削除</button>
    </div>
  );
}

/**
 * 廃棄依頼ページ
 */
function Component() {
  usePageTitle('廃棄依頼');

  const navigate = useNavigate();
  const { state } = useLocation();
  const openMessageModal = useMessageModal();
  const { documentWarehousingList }: any = state;

  const gridRef = useRef<AgGridReact<DocumentWarehousing>>(null);

  // States
  const [documentWarehousingListState, setDocumentWarehousingListState] = useState<DocumentWarehousing[]>(documentWarehousingList);
  const [form, setForm] = useState<DisposeReserveForm>({});
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);

  // Apis
  const disposeReserveApi = useDisposeReserveApi();
  const { request: createLog } = useCreateLogApi();

  // Effects
  useEffect(() => {
    setForm({ ...form, documentList: [...documentWarehousingListState.map((doc) => ({ documentId: doc.documentId }))] });
  }, [documentWarehousingListState]);

  // Methods
  const onDeleteDocumentWarehousingClick = useCallback((documentWarehousing: DocumentWarehousing) => {
    const newDocumentWarehousingList = documentWarehousingListState.filter((item: DocumentWarehousing) => item.documentId !== documentWarehousing.documentId);
    setDocumentWarehousingListState(newDocumentWarehousingList);
  }, [documentWarehousingListState]);

  const alertModalFunction = useCallback((text: string, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const handleSendForm = useCallback(async () => {
    if (form.disposalReportIssueDiv === undefined || !form.emergencyContacts) {
      openMessageModal('全ての必須項目を入力してください');
      return;
    }
    if (form.trdContact && form.trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return;
    }
    try {
      await disposeReserveApi.request(form);
      alertModalFunction('依頼が完了しました。', () => navigate(routes.main));
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [alertModalFunction, disposeReserveApi, form, navigate, openMessageModal]);

  // Memos
  const colDefs = useMemo((): ColDef<DocumentWarehousing>[] => [
    AGUtils.colDefault('ccControlNumber', '文書ID'),
    AGUtils.colDefault('documentName', '文書名', 250),
    AGUtils.colDefault('documentTypeName', '文書種類', 130),
    AGUtils.colDefault('documentFileName', 'ファイル名', 170),
    AGUtils.colFileSize('documentFileSize', 'サイズ'),
    AGUtils.colUsername('registUser', '登録者'),
    AGUtils.colDate('registDate', '登録日時'),
    AGUtils.colUsername('updateUser', '更新者'),
    AGUtils.colDate('updateDate', '更新日時'),
    AGUtils.colDefault('warehouseStatus', '預け入れステータス', 180),
    AGUtils.colDefault('itemCode', '保管品バーコード'),
    AGUtils.colDefault('barcodePrinting', 'バーコード印字欄'),
    {
      field: 'tagList',
      headerName: 'タグ',
      resizable: true,
      minWidth: 130,
      flex: 1,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => {
        const value = data?.tagList;
        if (!value) return '';
        const tagLabels = value.map((tag: TagList) => tag.tagLabel);
        return tagLabels.join(', ');
      },
    },
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 80,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDeleteClick: onDeleteDocumentWarehousingClick,
      },
    },
  ], [documentWarehousingListState]);

  useEffectOnce(() => {
    createLog(LogFormName.DisposalRequestScreen, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles['mainframe-body']}>
            <header>
              廃棄
            </header>
            <div className={styles['table-container']}>
              <BaseTable<DocumentWarehousing>
                tableRef={gridRef}
                formName="disposalRequestTable"
                rowData={documentWarehousingListState}
                columnDefs={colDefs}
                createLogOnDownloadTableData={LogFormName.DisposalRequestScreen}
              />
            </div>
            <div className={styles.wrapper}>
              <div>
                <div className={styles['label-container']}>
                  <label>廃棄処理報告書発行</label>
                  <span>*</span>
                </div>
                <SelectInput
                  value={form.disposalReportIssueDiv}
                  options={[{ value: 0, text: '要' }, { value: 1, text: '不要' }]}
                  onChange={(e) => setForm({ ...form, disposalReportIssueDiv: e })}
                  style={{ width: '280px' }}
                  menuStyle={{ width: '100%' }}
                />
                <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
                  <span className={mainStyles['mr-1']}>※</span>
                  廃棄処理報告書は廃棄依頼者のメールアドレスへ送付いたします。
                </div>
              </div>

              <div>
                <div className={styles['label-container']}>
                  <label>寺田倉庫への連絡事項</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.trdContact}
                  placeholder="100文字以内で入力してください"
                  onChange={(e) => setForm({ ...form, trdContact: e.target.value })}
                />
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                <div className={styles['label-container']}>
                  <label>緊急連絡先</label>
                  <span>*</span>
                </div>
                <Input
                  type="text"
                  className={[mainStyles.input, styles.input].join(' ')}
                  value={form.emergencyContacts || ''}
                  onChange={(e) => setForm({ ...form, emergencyContacts: e })}
                />
                <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
                  <span className={mainStyles['mr-1']}>※</span>
                  日中に連絡可能な連絡先をご記入ください
                </div>
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>お客様メモ</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.userMemo}
                  onChange={(e) => setForm({ ...form, userMemo: e.target.value })}
                />
              </div>
            </div>
          </div>
          <footer className={styles['body-footer']}>
            <Button color="lightGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
            <Button
              size="smaller"
              onClick={handleSendForm}
              loading={disposeReserveApi.loading}
              disabled={!documentWarehousingListState.length}
            >
              依頼
            </Button>
          </footer>
          <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
        </div>
)}
    />
  );
}

export default Component;
