import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './setFirstPasswordSuccessPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import routes from '../../utils/routes';
import usePageTitle from '../../hooks/title.hook';

/**
 * 初期パスワード設定完了ページ
 */
function SetFirstPasswordSuccessPage() {
  const navigate = useNavigate();

  usePageTitle('初期パスワード変更完了');

  return (
    <CustomFrame
      title="初期パスワード変更完了"
      desc={(
        <div className={styles.descTitle}>
          <p>
            初期パスワードの変更が完了しました
          </p>
        </div>
      )}
      body={<Button size="larger" content="ホーム画面へ" onClick={() => navigate(routes.main)} />}
    />
  );
}

export default SetFirstPasswordSuccessPage;
