import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import MainFrameLeftSection from '../../components/MainFrameLeftSection/mainFrameLeftSection';
// eslint-disable-next-line import/no-cycle
import MainFrameRightSection, {
  DocumentsAndSearchType, Pagination, SearchType, defaultDocumentsAndSearchType, defaultPagination,
} from '../../components/MainFrameRightSection/mainFrameRightSection';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './mainPage.module.css';
import usePageTitle from '../../hooks/title.hook';
import { Folder } from '../../services/http/folder.api';
import FolderPermissionsSection from '../../components/FolderPermissions/folderPermissionsSection';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useDocumentSearch, useDocumentSearchDetail, useDocumentSearchFolder } from '../../hooks/api/document.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import {
  DocumentDetail, DocumentSearchDetailForm, SearchUrlForm, SortList,
} from '../../services/http/documents.api';
import LoadingOverlay from '../../components/LoadingOverlay';
import TopBar from '../../components/TopBar/topBar';
import SearchInputTop, { ContractType, WarehouseStatusType } from '../../components/SearchInputTop';
import { CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import TopBarTagBulkButton from '../../components/TopBar/topBarTagBulkButton';

/**
 * 文書一覧メインページ
 */
function Component() {
  usePageTitle('文書一覧');
  // states
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [selectedFolderBackup, setSelectedFolderBackup] = useState<Folder | null>(null);
  const [changingFolderPermissions, setChangingFolderPermissions] = useState(false);
  const [updateFolderList, setUpdateFolderList] = useState(0);
  // ------------------
  const [isSearching, setIsSearching] = useState(false);
  const [sort, setSort] = useState<SortList[] | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [documentsAndSearchType, setDocumentsAndSearchType] = useState<DocumentsAndSearchType>(defaultDocumentsAndSearchType);
  const [advancedSearchForm, setAdvancedSearchForm] = useState<DocumentSearchDetailForm | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [highlightTexts, setHighlightTexts] = useState<string[]>([]);
  const [contractType, setContractType] = useState<ContractType>(ContractType.ALL);
  const [warehouseStatus, setWarehouseStatus] = useState<WarehouseStatusType>(WarehouseStatusType.ALL);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [isFolderListLoading, setIsFolderListLoading] = useState(false);
  const [isFolderListEmpty, setIsFolderListEmpty] = useState(false);
  const [dropCompletedCounter, setDropCompletedCounter] = useState(0);

  const { request: createLog } = useCreateLogApi();
  const { request: documentSearchFolderRequest, loading: loadingFolder } = useDocumentSearchFolder();
  const { request: documentSearchDetailRequest, loading: loadingAdvancedSearch } = useDocumentSearchDetail();
  const { exec: documentSearchRequest, loading: loadingSearch } = useDocumentSearch();
  const openMessageModal = useMessageModal();
  const navigate = useNavigate();

  const isLoading = loadingFolder || loadingAdvancedSearch || loadingSearch;

  const onClearSearch = useCallback(() => {
    setSearchText('');
    setIsSearching(false);
  }, []);

  const onClearAdvancedSearch = useCallback(() => {
    setAdvancedSearchForm(null);
  }, []);

  const searchFolderDocument = useCallback(async (_folder: Folder, page: number, sortList = sort) => {
    setHighlightTexts([]);
    try {
      const res = await documentSearchFolderRequest({ folder: _folder, page, sortList: sortList || [] });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.FOLDER });
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchFolderRequest, openMessageModal, sort]);

  const advancedSearchDocument = useCallback(async (form: DocumentSearchDetailForm, page: number, sortList = sort) => {
    setSort(sortList);
    setHighlightTexts([]);
    try {
      const res = await documentSearchDetailRequest({ ...form, page, sortList: sortList || [] });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.ADVANCED });
      setIsSearching(true);
      setSelectedFolder(null);
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
    } catch (e) {
      setIsSearching(false);
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchDetailRequest, openMessageModal, sort]);

  const searchDocument = useCallback(async (text: string, page: number, sortList = sort) => {
    setSort(sortList);
    try {
      const res = await documentSearchRequest({ page, sortList: sortList || [], simpleString: text });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.SIMPLE });
      setIsSearching(true);
      setSelectedFolder(null);
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
      setHighlightTexts(res.highlightWordList);
    } catch (e) {
      setIsSearching(false);
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchRequest, openMessageModal, sort]);

  const handleFolderSelect = useCallback((folder: Folder) => {
    if (selectedFolder && (selectedFolder.id === folder.id)) return;
    setSelectedFolder(folder);
    setSelectedFolderBackup(folder);
    searchFolderDocument(folder, 1);
    setIsSearching(false);
    setSearchText(null);
    setContractType(ContractType.ALL);
    setWarehouseStatus(WarehouseStatusType.ALL);
  }, [searchFolderDocument, selectedFolder]);

  const handlePageChange = useCallback((page: number) => {
    if (documentsAndSearchType.searchType === SearchType.ADVANCED && advancedSearchForm) {
      advancedSearchDocument(advancedSearchForm, page);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.SIMPLE && searchText !== null) {
      searchDocument(searchText, page);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.FOLDER && selectedFolder) {
      searchFolderDocument(selectedFolder, page);
    }
  }, [advancedSearchDocument, advancedSearchForm, documentsAndSearchType.searchType, searchDocument, searchFolderDocument, searchText, selectedFolder]);

  const handleAdvancedSearch = useCallback((form: DocumentSearchDetailForm) => {
    setSearchText(null);
    advancedSearchDocument(form, 1);
    setAdvancedSearchForm(form);
  }, [advancedSearchDocument]);

  const handleSearch = useCallback((text: string) => {
    searchDocument(text, 1);
    setSearchText(text);
  }, [searchDocument]);

  const handleCloseSearchSection = useCallback(() => {
    setIsSearching(false);
    setDocumentsAndSearchType(defaultDocumentsAndSearchType);
    setSearchText(null);
    setAdvancedSearchForm(null);
    setContractType(ContractType.ALL);
    setWarehouseStatus(WarehouseStatusType.ALL);
    if (!selectedFolderBackup) return;
    handleFolderSelect(selectedFolderBackup);
  }, [handleFolderSelect, selectedFolderBackup]);

  const onRefresh = useCallback((page?: number, sortList?: SortList[]) => {
    if (documentsAndSearchType.searchType === SearchType.ADVANCED && advancedSearchForm) {
      advancedSearchDocument(advancedSearchForm, page || 1, sortList);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.SIMPLE && searchText !== null) {
      searchDocument(searchText, page || 1, sortList);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.FOLDER && selectedFolder) {
      searchFolderDocument(selectedFolder, page || 1, sortList);
    }
  }, [advancedSearchDocument, advancedSearchForm, documentsAndSearchType.searchType, searchDocument, searchFolderDocument, searchText, selectedFolder]);

  const onSortChanged = useCallback((sortList: SortList[]) => {
    let result = sortList;

    if (sortList.length > 0) {
      const { order } = sortList[0];
      let { item } = sortList[0];
      item = item === 'name' ? 'documentName' : item;
      result = [{ item, order }];
    }

    setSort(result);
    if (pagination.totalPages !== 1) {
      onRefresh(1, result);
    }
  }, [onRefresh, pagination.totalPages]);

  const onRemoveDocument = useCallback((e: DocumentDetail) => {
    setDocumentsAndSearchType((prev) => ({
      ...prev,
      documents: prev.documents.filter((document) => document.id !== e.id),
    }));
  }, []);

  const handleInvalidSearchAlertModal = useCallback(() => {
    const text = '検索条件が無効です。';
    setCloseAlertModal((prev) => ({
      ...prev,
      open: true,
      text,
      onCancel: () => {
        setCloseAlertModal({ ...prev, open: false });
        navigate('/DCD16001C');
      },
    }));
  }, [navigate]);

  const getSearchDetailForm = useCallback((name: string | null, memo: string | null, label: string, value1: string) => {
    const form: SearchUrlForm = {};

    form.name = name || form.name;
    form.memo = memo || form.memo;

    form.tagList = [{
      label,
      condition: '次の値と一致',
      value1,
    },
    ];

    return form;
  }, []);

  const handleDropCompleted = () => {
    setDropCompletedCounter((prev) => prev + 1);
  };

  useEffectOnce(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('documentName');
    const memo = urlParams.get('memo');
    const label = urlParams.get('tag');
    const value1 = urlParams.get('date');

    if (name || memo || label || value1) {
      if (!label || !value1) {
        handleInvalidSearchAlertModal();
        return;
      }
    } else {
      return;
    }

    const searchDetailForm = getSearchDetailForm(name, memo, label, value1);

    if (Object.keys(searchDetailForm).length > 0) {
      handleAdvancedSearch(searchDetailForm);
    }
  });

  useEffect(() => {
    if (selectedFolder && selectedFolder.id) {
      setChangingFolderPermissions(false);
    }
  }, [selectedFolder]);

  useEffectOnce(() => {
    createLog(LogFormName.Main, LogControlName.Show);
  });

  return (
    <div>
      <MainFrame
        borderBox
        topBarDisplay={false}
        onClearSearch={onClearSearch}
        onClearAdvancedSearch={onClearAdvancedSearch}
      >
        <div className={styles.mainFrame}>
          <TopBar>
            <>
              <SearchInputTop
                text={searchText || ''}
                isSearching={isSearching}
                contractType={contractType}
                warehouseStatus={warehouseStatus}
                onAdvancedSearch={handleAdvancedSearch}
                onSearch={handleSearch}
                onTextChange={setSearchText}
                onChangeContractType={setContractType}
                onChangeWarehouseStatus={setWarehouseStatus}
              />
              <TopBarTagBulkButton />
            </>
          </TopBar>
          <div className={styles.mainSections}>
            <MainFrameLeftSection
              onDocumentFolderChanged={() => onRefresh(pagination.page, sort)}
              folder={selectedFolder}
              onSelectFolder={handleFolderSelect}
              isChangingFolderPermissions={changingFolderPermissions}
              updateFolderList={updateFolderList}
              onFolderListSearchLoading={setIsFolderListLoading}
              onFolderListSearchEmpty={setIsFolderListEmpty}
              onDropCompleted={handleDropCompleted}
            />
            {!changingFolderPermissions && (
            <MainFrameRightSection
              folder={selectedFolder}
              documentsAndSearchType={documentsAndSearchType}
              pagination={pagination}
              isSearching={isSearching}
              highlightTexts={highlightTexts}
              contractType={contractType}
              warehouseStatus={warehouseStatus}
              isFolderListLoading={isFolderListLoading}
              isFolderListEmpty={isFolderListEmpty}
              onPageChanged={handlePageChange}
              onChangingFolderPermissions={() => setChangingFolderPermissions(true)}
              onCloseSearchSection={handleCloseSearchSection}
              onSortChanged={onSortChanged}
              onRemoveDocument={onRemoveDocument}
              onRefresh={onRefresh}
              dropCompletedTrigger={dropCompletedCounter}
            />
            )}
            {changingFolderPermissions && (
            <FolderPermissionsSection
              folder={selectedFolder}
              onCancel={() => setChangingFolderPermissions(false)}
              updateFolderList={() => setUpdateFolderList((prev) => prev + 1)}
            />
            )}
          </div>
        </div>
      </MainFrame>
      <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
      {isLoading ? <LoadingOverlay /> : null}
    </div>
  );
}

export default Component;
