import { useState } from 'react';
import { ApiError } from '../../services/http';
import reserveManagementApi, {
  ReserveManagementDetailList,
  ReserveManagementItem,
  ReserveManagementList,
  ReserveManagementSearchForm,
} from '../../services/http/reserveManagement.api';

/**
 * 予約管理を検索APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useSearchReserveManagementApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ReserveManagementList[] | null>([]);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: ReserveManagementSearchForm): Promise<ReserveManagementList[]> {
    setLoading(true);
    try {
      const doc = await reserveManagementApi.search(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, data, error, request,
  };
}

/**
 * 予約管理詳細リストを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetReserveManagementDetailListApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ReserveManagementDetailList[]>([]);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(reserveManagementItem: ReserveManagementItem): Promise<ReserveManagementDetailList[]> {
    setLoading(true);
    try {
      const doc = await reserveManagementApi.getReserveManagementDetail(reserveManagementItem);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, data, error, request,
  };
}
