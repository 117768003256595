import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { ReserveManagementItem } from './reserveManagement.api';

export interface DocumentWarehousingSearchForm {
  documentList: DocumentId[],
}

export type ContractFlag = 1 | 0;

export interface DocumentWarehousing {
  ccControlNumber: number,
  documentId: number | null,
  documentName: string,
  documentFileName?: string,
  documentFileSize?: number,
  documentTypeId?: number,
  documentTypeName?: string,
  tagList: TagList[],
  relationalDocumentCount?: number,
  warehouseStatus?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  registUser: string,
  registDate: Date,
  updateUser: string,
  updateDate: Date,
  itemCode: string,
  barcodePrinting: string,
}

export interface TagList {
  tagLabel: string,
  tagValue: string,
}

export interface DocumentWarehousingStoreForm {
  documentList?: DocumentId[],
  scheduledDate?: string,
  trdContact?: string,
  emergencyContacts?: string,
  userMemo?: string,
}

export interface DocumentId {
  documentId: number | null,
}

export interface DocumentsSearchForReserveNoRequest {
  ReserveList: { ReserveNo: string; type: string }[]
}

function reduceDocumentWarehousing(res:AxiosResponse<any, any>) {
  const { data } = res;
  return data.documentList.map((item: {
    ccControlNumber: number,
    documentId: number,
    documentName: string,
    documentFileName: string,
    documentFileSize: number,
    documentTypeId: number,
    documentTypeName: string,
    tagList: { tagLabel: string, tagValue: string }[],
    relationalDocumentCount: number,
    warehouseStatus: string,
    paperFlg: ContractFlag,
    electronicFlg: ContractFlag,
    registUser: string,
    registDate: string,
    updateUser: string,
    updateDate: string,
    itemCode: string,
    barcodePrinting: string,
  }) => ({
    ccControlNumber: item.ccControlNumber,
    documentId: item.documentId,
    documentName: item.documentName,
    documentFileName: item.documentFileName,
    documentFileSize: item.documentFileSize,
    documentTypeId: item.documentTypeId,
    documentTypeName: item.documentTypeName,
    tagList: item.tagList.map((tag: { tagLabel: string, tagValue: string }) => ({
      tagLabel: tag.tagLabel,
      tagValue: tag.tagValue,
    })),
    relationalDocumentCount: item.relationalDocumentCount,
    warehouseStatus: item.warehouseStatus,
    paperFlg: item.paperFlg,
    electronicFlg: item.electronicFlg,
    registUser: item.registUser,
    registDate: DateTime.fromISO(item.registDate).toJSDate(),
    updateUser: item.updateUser,
    updateDate: DateTime.fromISO(item.updateDate).toJSDate(),
    itemCode: item.itemCode,
    barcodePrinting: item.barcodePrinting,
  }));
}
export default {
  /**
   * 文書の保管庫への保管を行います。
   *
   * @param form - 文書保管フォーム
   * @returns - 空のプロミス
   */
  async store(form: DocumentWarehousingStoreForm): Promise<void> {
    const res = await axios.post('/StoreReserve/confirm', form);
    const { data } = res;
    return data;
  },

  /**
   * 保管番号による文書検索を行います。
   *
   * @param form - 文書検索フォーム
   * @returns - 保管文書の配列
   */
  async searchForReserveNo(nos: ReserveManagementItem[]): Promise<DocumentWarehousing[]> {
    const form = { ReserveList: nos };
    const res = await axios.post('/Documents/searchForReserveNo', form);
    return reduceDocumentWarehousing(res);
  },
};
