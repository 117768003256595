import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/button';
import styles from './setFirstPasswordPage.module.css';
import CustomFrame from '../../components/CustomFrame/customFrame';
import { useChangeInitPasswordApi } from '../../hooks/api/auth.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import Input from '../../components/Input/input';
import usePageTitle from '../../hooks/title.hook';
import routes from '../../utils/routes';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 初期パスワード設定ページ
 */
function SetFirstPasswordPage() {
  // Context
  const navigator = useNavigate();
  const openMessageModal = useMessageModal();

  // States
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [error, setError] = useState('');

  // Custom Hooks
  const changeFirstPasswordApi = useChangeInitPasswordApi();
  const { request: createLog } = useCreateLogApi();

  usePageTitle('初期パスワード変更');

  const sendForm = async (): Promise<void> => {
    try {
      Validator.validPasswordLength(password);
      Validator.validPasswordTypeChars(password);
      Validator.validPasswordChars(password);
      Validator.comparePassword(password, confirmedPassword);
      await changeFirstPasswordApi.request({ password });
      return navigator(routes.setFirstPasswordSuccess);
    } catch (e) {
      setError((e as Error).message);
      openMessageModal((e as ApiError)?.message);
    }
    return undefined;
  };

  useEffectOnce(() => {
    createLog(LogFormName.SetFirstPassword, LogControlName.Show);
  });

  return (
    <CustomFrame
      title="初期パスワード変更"
      desc={(
        <div>
          <div className={styles.formDesc}>
            <p className={styles.firstParagraph}>アカウントの初期パスワード変更を行ってください</p>
            <p>{`${Validator.passwordLengthMin}~${Validator.passwordLengthMax}文字で、大文字英、小文字英、数字の3種類(いずれも半角)を必ず含めてください`}</p>
            <p>{`利用可能な文字は半角英数字及び${Validator.passwordSymbolsLength()}種類の記号(${Validator.passwordSymbols})となります`}</p>
          </div>

          <div className={styles.form}>
            <div className={styles.formAlert}> </div>
            <div className={styles.formItem}>
              <div className={styles.formIteField}>パスワード</div>
              <div><Input type="password" value={password} onChange={setPassword} /></div>
            </div>
            <div className={styles.formItem}>
              <div className={styles.formIteField}>パスワード(確認用)</div>
              <div><Input type="password" value={confirmedPassword} onChange={setConfirmedPassword} /></div>
            </div>
            <div className={styles.errorText}>{error}</div>
          </div>

          {changeFirstPasswordApi.loading && <LoadingOverlay />}
        </div>
      )}
      body={<Button content="設定" onClick={sendForm} />}
    />
  );
}

export default SetFirstPasswordPage;
