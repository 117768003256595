import { useEffect, useState } from 'react';
import styles from './supplierDetailModal.module.css';
import mainStyles from '../main.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import { Supplier, SupplierDetails } from '../../services/http/supplier.api';
import { useGetSupplierDetailsApi } from '../../hooks/api/supplier.hook';
import Button from '../../components/Button/button';
import SupplierFirstUserRegistrationModal from './supplierFirstUserRegistrationModal';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 取引先詳細モーダルのプロップスインターフェース
 */
export interface Props {
  /** 取引先参照 */
  supplier: Supplier | null | undefined;
  /** キャンセルハンドラー */
  handleCancel: () => void;
}

/**
 * 取引先詳細モーダルのコンポーネント
*/
function Component({ supplier: supplierRef, handleCancel }: Props) {
  const openMessageModal = useMessageModal();

  // States
  const [supplierDetails, setSupplierDetails] = useState<SupplierDetails | null>(null);
  const [firstUserRegistrationModalDisplay, setFirstUserRegistrationModalDisplay] = useState(false);

  // Apis
  const supplierGetDetailsApi = useGetSupplierDetailsApi(0);
  const { request: createLog } = useCreateLogApi();

  // Effects
  useEffect(() => {
    setSupplierDetails(null);
    if (!supplierRef) return;
    try {
      supplierGetDetailsApi.request({ id: supplierRef.customerId }).then((res) => {
        setSupplierDetails(res);
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [supplierRef]);

  useEffectOnce(() => {
    createLog(LogFormName.SupplierDetail, LogControlName.Show);
  });

  return (
    <ScreenModal
      customStyles={{
        minHeight: '580px',
        minWidth: '1000px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          取引先詳細
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles.label}>
          取引先コード
        </div>
        <div className={styles.text}>
          {supplierDetails?.customerCd}
        </div>
        <div className={styles.label}>
          取引先名称
        </div>
        <div className={styles.text}>
          {supplierDetails?.customerName}
        </div>
        <div className={styles.label}>
          テナントコード
        </div>
        <div className={styles.text}>
          {supplierDetails?.tenantCode}
        </div>
        <div className={styles.label}>
          荷主
        </div>
        <div className={styles.text}>
          {supplierDetails?.ownerCd}
          {' : '}
          {supplierDetails?.ownerName}
        </div>
        <div className={styles.label}>
          契約商品
        </div>
        <div className={styles.text}>
          {supplierDetails?.contractCode}
          {' : '}
          {supplierDetails?.contractName}
        </div>
        <div className={[mainStyles['mt-4'], mainStyles['mb-4']].join(' ')}>
          {(supplierDetails && (!supplierDetails.existFirstUser || supplierDetails.passwordUpdate === 0)) && (
            <Button
              className={[mainStyles.button, mainStyles['btn-warning']].join(' ')}
              style={{ width: '200px' }}
              onClick={() => setFirstUserRegistrationModalDisplay(true)}
            >
              {supplierDetails.existFirstUser ? 'ファーストユーザーを変更' : 'ファーストユーザーを作成'}
            </Button>
          )}
        </div>
        <div className={[styles.label].join(' ')}>
          登録情報
        </div>
        <div className={[mainStyles['d-flex'], mainStyles['mt-3']].join(' ')}>
          <div className={[mainStyles['mr-5'], mainStyles['ml-3'], styles.text].join(' ')}>
            <div>登録者</div>
            <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
              {supplierDetails?.registerUser}
            </div>
          </div>
          <div className={[mainStyles['ml-5'], styles.text].join(' ')}>
            <div>登録日時</div>
            <div className={[mainStyles['mb-3'], styles['font-small']].join(' ')}>
              {supplierDetails && Formatter.toDisplayDate(supplierDetails.registerDate, Formatter.defaultDateTimeFormat) }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['modal-foooter']}>
        <div className={mainStyles['p-10px']}>
          <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
        </div>
      </div>
      { firstUserRegistrationModalDisplay && (
      <SupplierFirstUserRegistrationModal
        hidden={!firstUserRegistrationModalDisplay}
        handleCancel={() => setFirstUserRegistrationModalDisplay(false)}
        firstUserCreated={() => {
          setFirstUserRegistrationModalDisplay(false);
          handleCancel();
        }}
        customerId={supplierRef?.customerId}
      />
      )}
    </ScreenModal>

  );
}

export default Component;
