import { CSSProperties } from 'react';
import styles from './screenModal.module.css';

interface Props {
  children: React.ReactNode
  customStyles?: CSSProperties
  handleCancel?: () => void;
}

function Component({
  children, customStyles, handleCancel,
}:Props) {
  return (
    <div className={styles.alertBox} style={{ display: 'block' }}>
      <div
        className={styles.modalContainer}
        role="dialog"
        onClick={handleCancel}
      >
        <div className={[styles.modal1].join(' ')} style={customStyles} role="dialog" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Component;
