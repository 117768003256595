import { useCallback, useEffect, useState } from 'react';
import styles from './mainFrameLeftSection.module.css';
import Button from '../Button/button';
import { useFolderSearch } from '../../hooks/api/folder.hook';
import { Folder, FolderDeleteForm, FolderUpdateForm } from '../../services/http/folder.api';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import { FolderForm } from './partials/folderForm';
import { FolderItem } from './partials/folderItem';

/**
 * メインフレームの左部メニューのプロップスインターフェース
 */
export interface MainFrameLeftSectionProps {
  /** 選択されているフォルダー */
  folder: Folder | null,
  /** フォルダー選択時に実行されるハンドラー */
  onSelectFolder: (folder: Folder) => void,
  /** フォルダー権限変更中かどうか */
  isChangingFolderPermissions: boolean,
  /** 上部ボタンを非表示にするかどうか */
  hideTopButton?: boolean,
  /** 文書フォルダー変更時に実行されるハンドラー */
  onDocumentFolderChanged?: () => void,
  /** フォルダー一覧を更新するための値 */
  updateFolderList?: number,
  onFolderListSearchLoading?: (isLoading: boolean) => void,
  onFolderListSearchEmpty?: (isEmpty: boolean) => void,
  onDropCompleted?: () => void
}

/**
 * メインフレームの左部メニューコンポーネント
 */
function MainFrameLeftSection({
  folder, onSelectFolder, isChangingFolderPermissions, hideTopButton, onDocumentFolderChanged, updateFolderList, onFolderListSearchLoading = () => {}, onFolderListSearchEmpty = () => {}, onDropCompleted,
}: MainFrameLeftSectionProps) {
  const [folders, setFolders] = useState<Folder[]>([]);
  const [newFolderFlag, setNewFolderFlag] = useState(false);
  const { user } = useAuthContext();

  const folderSearch = useFolderSearch();

  const fetchFolders = useCallback(async () => {
    onFolderListSearchLoading(true);
    const result = await folderSearch.exec({});
    setFolders(result);
    onFolderListSearchLoading(false);
    onFolderListSearchEmpty(result.length === 0);
  }, []);

  const onCreateFolder = useCallback(() => {
    setNewFolderFlag(false);
    fetchFolders();
  }, [fetchFolders]);

  const onCancelCreateFolder = useCallback(() => {
    setNewFolderFlag(false);
  }, []);

  const onUpdateForm = useCallback(async (form: FolderUpdateForm): Promise<void> => {
    setFolders(folders.map((item) => (item.id === form.id ? { ...item, name: form.name } : item)));
    fetchFolders();
  }, [fetchFolders, folders]);

  const onDeleteForm = useCallback(async (form: FolderDeleteForm): Promise<void> => {
    setFolders(folders.filter((item) => item.id !== form.id));
    fetchFolders();
  }, [fetchFolders, folders]);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders, updateFolderList]);

  return (
    <div className={styles.mainFrameLeftSection}>
      <div className={styles.mainFrameLeftSectionTopLine} />
      <div className={styles.mainFrameLeftSectionTop}>
        <div style={{ marginTop: '10px' }}>
          {(!isChangingFolderPermissions && !hideTopButton && user?.managementType === ManagementType.Admin) && (
            <Button className={styles.addFolderButton} size="smaller" fullWidth onClick={() => setNewFolderFlag(true)} />
          )}
        </div>
      </div>
      <div />
      <div className={styles.mainFrameLeftSectionList}>
        {newFolderFlag && (<FolderForm folders={folders} onCreate={onCreateFolder} onCancel={onCancelCreateFolder} />)}
        {folders.map((item) => (
          <FolderItem
            onDocumentFolderChanged={onDocumentFolderChanged}
            key={item.id + 1}
            folder={item}
            folders={folders}
            selected={folder?.id === item.id}
            onSelect={onSelectFolder}
            onEdit={onUpdateForm}
            onDelete={onDeleteForm}
            onDropCompleted={onDropCompleted}
          />
        ))}
      </div>
    </div>
  );
}

export default MainFrameLeftSection;
