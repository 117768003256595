import { AgGridReact } from 'ag-grid-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './issueRequestScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import { DocumentWarehousing, TagList } from '../../services/http/documentWarehousing.api';
import BaseTable from '../../components/BaseTable';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import { CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import { useShipReserveApi, useShipReserveGetCalendarApi } from '../../hooks/api/reserve.hook';
import { useCollectAndDeliverSearchApi } from '../../hooks/api/collectAndDeliver.hook';
import { CollectAndDeliver } from '../../services/http/collectAndDeliver.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import Input from '../../components/Input/input';
import { StoreReserveForm } from '../../services/http/reserve.api';
import Spinner from '../../components/Spinner';
import Breadcrumb from '../../components/Breadcrumb';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * 倉庫依頼のプロップスインターフェース
 */
export interface WareHousingRequestProps {
  /** "削除" ボタンがクリックされた時に実行されるハンドラー */
  onDeleteClick: (documentWarehousing: DocumentWarehousing) => void;
  /** テーブルセルレンダリングされるユーザーデータ */
  data: DocumentWarehousing;
}

/**
 * テーブルのセル’ボタン’をレンダリングするコンポーネント
 */
export function TableBtnCellRender({ data, onDeleteClick }: WareHousingRequestProps) {
  return (
    <div>
      <button type="button" className={[mainStyles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onDeleteClick(data)}>削除</button>
    </div>
  );
}

/**
 * 出庫依頼ページのコンポーネント
 */
function Component() {
  usePageTitle('出庫依頼');

  const navigate = useNavigate();
  const { state } = useLocation();
  const { documentWarehousingList }: any = state;
  const openMessageModal = useMessageModal();

  const gridRef = useRef<AgGridReact<DocumentWarehousing>>(null);

  // States
  const [documentWarehousingListState, setDocumentWarehousingListState] = useState<DocumentWarehousing[]>(documentWarehousingList);
  const [form, setForm] = useState<StoreReserveForm>({});
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [collectAndDelivers, setCollectAndDelivers] = useState<CollectAndDeliver[]>([]);
  const [selectedCollectAndDeliver, setSelectedCollectAndDeliver] = useState<CollectAndDeliver | null>(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<Date | ''>('');

  // Apis
  const { request: collectAndDeliverSearchApiRequest } = useCollectAndDeliverSearchApi();
  const shipReserve = useShipReserveApi();
  const getShipReserveCalendar = useShipReserveGetCalendarApi();
  const { request: createLog } = useCreateLogApi();

  // Effects
  useEffect(() => {
    collectAndDeliverSearchApiRequest().then((res) => setCollectAndDelivers(res));
  }, []);

  useEffect(() => {
    setForm({ ...form, documentList: [...documentWarehousingListState.map((doc) => ({ documentId: doc.documentId }))] });
  }, [documentWarehousingListState]);

  // Methods
  const alertModalFunction = useCallback((text: string, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const onDeleteDocumentWarehousingClick = useCallback((documentWarehousing: DocumentWarehousing) => {
    const newDocumentWarehousingList = documentWarehousingListState.filter((item: DocumentWarehousing) => item.documentId !== documentWarehousing.documentId);
    setDocumentWarehousingListState(newDocumentWarehousingList);
  }, [documentWarehousingListState]);

  const resetStates = useCallback(() => {
    setForm({});
    setSelectedCollectAndDeliver(null);
    setExpectedDeliveryDate('');
  }, []);

  const handleSendForm = useCallback(async () => {
    if (!form.deliveryCd) {
      alertModalFunction('配送先を入力してください。', () => setCloseAlertModal({ ...closeAlertModal, open: false }));
      return;
    }
    if (form.trdContact && form.trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return;
    }
    if (form.deliveryCd === undefined || form.deliveryName === '' || form.deliveryPhone === '') {
      openMessageModal('全ての必須項目を入力してください');
      return;
    }
    try {
      await shipReserve.request(form);
      alertModalFunction('依頼が完了しました。', () => navigate(routes.main));
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [alertModalFunction, closeAlertModal, form, navigate, openMessageModal, shipReserve]);

  const handleSelectedCollectAndDelivers = useCallback(async (collectAndDeliver: CollectAndDeliver) => {
    setSelectedCollectAndDeliver(collectAndDeliver);

    if (collectAndDeliver === undefined) {
      resetStates();
      return;
    }

    if (collectAndDeliver) {
      const getShipReserveCalendarForm = {
        deliveryCd: collectAndDeliver.deliveryCd,
        deliveryPostNo: collectAndDeliver.deliveryPostNo,
        deliveryAddress1: collectAndDeliver.deliveryAddress1,
        deliveryAddress2: collectAndDeliver.deliveryAddress2,
        deliveryAddress3: collectAndDeliver.deliveryAddress3,
      };

      try {
        const res = await getShipReserveCalendar.request(getShipReserveCalendarForm);
        setExpectedDeliveryDate(res.shortestDesiredDate);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }

      setForm({
        documentList: [...documentWarehousingListState.map((doc) => ({ documentId: doc.documentId }))],
        deliveryCd: collectAndDeliver.deliveryCd,
        deliveryName: collectAndDeliver.deliveryName3,
        deliveryPostNo: collectAndDeliver.deliveryPostNo,
        deliveryAddress1: collectAndDeliver.deliveryAddress1,
        deliveryAddress2: collectAndDeliver.deliveryAddress2,
        deliveryAddress3: collectAndDeliver.deliveryAddress3,
        customerStaffName: collectAndDeliver.deliveryName3,
        deliveryPhone: collectAndDeliver.deliveryPhone,
        deliveryFax: collectAndDeliver.deliveryFax,
        comments1: collectAndDeliver.deliveryName4,
      });
    }
  }, [documentWarehousingListState, getShipReserveCalendar, resetStates]);

  // Memos
  const collectAndDeliverOptions = useMemo(
    () => {
      const res = collectAndDelivers.map((item) => ({ text: `${item.deliveryCd}：${item.deliveryName}`, value: item }));
      return [{ text: '', value: undefined as unknown as CollectAndDeliver }, ...res];
    },
    [collectAndDelivers],
  );

  const colDefs = useMemo((): ColDef<DocumentWarehousing>[] => [
    AGUtils.colDefault('ccControlNumber', '文書ID'),
    AGUtils.colDefault('documentName', '文書名', 250),
    AGUtils.colDefault('documentTypeName', '文書種類', 130),
    AGUtils.colDefault('documentFileName', 'ファイル名', 170),
    AGUtils.colFileSize('documentFileSize', 'サイズ'),
    AGUtils.colUsername('registUser', '登録者'),
    AGUtils.colDate('registDate', '登録日時'),
    AGUtils.colUsername('updateUser', '更新者'),
    AGUtils.colDate('updateDate', '更新日時'),
    AGUtils.colDefault('warehouseStatus', '預け入れステータス', 180),
    AGUtils.colDefault('itemCode', '保管品バーコード'),
    AGUtils.colDefault('barcodePrinting', 'バーコード印字欄'),
    {
      field: 'tagList',
      headerName: 'タグ',
      resizable: true,
      minWidth: 130,
      flex: 1,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => {
        const value = data?.tagList;
        if (!value) return '';
        const tagLabels = value.map((tag: TagList) => tag.tagLabel);
        return tagLabels.join(', ');
      },
    },
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 80,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDeleteClick: onDeleteDocumentWarehousingClick,
      },
    },
  ], [documentWarehousingListState]);

  useEffectOnce(() => {
    createLog(LogFormName.IssueRequestScreen, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles['mainframe-body']}>
            <header>
              出庫
            </header>
            <div className={styles['table-container']}>
              <BaseTable<DocumentWarehousing>
                tableRef={gridRef}
                formName="issueRequestTable"
                rowData={documentWarehousingListState}
                columnDefs={colDefs}
                createLogOnDownloadTableData={LogFormName.IssueRequestScreen}
              />
            </div>
            <div className={styles.wrapper}>
              <div>
                <div className={styles['label-container']}>
                  <label>配送先</label>
                  <span>*</span>
                </div>
                <AutoCompleteInput
                  value={selectedCollectAndDeliver ? `${selectedCollectAndDeliver.deliveryCd}：${selectedCollectAndDeliver.deliveryName}` : ''}
                  options={collectAndDeliverOptions}
                  onSelect={handleSelectedCollectAndDelivers}
                  style={{ width: '280px' }}
                  menuStyle={{ width: '100%' }}
                  onTextInput={() => {}}
                />
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>出庫予定日</label>
                </div>
                {getShipReserveCalendar.loading && (
                <div className={styles.loadingContainer}>
                  <Spinner borderWidth={3} size={20} />
                </div>
                )}
                {expectedDeliveryDate && (
                <>
                  <div>
                    {Formatter.toDisplayDate(expectedDeliveryDate, Formatter.defaultDateFormat)}
                  </div>
                  <div className={styles.info}>
                    <input
                      className={styles['info-button']}
                      type="image"
                      src="/images/Warning.svg"
                      alt="bubble-button"
                    />
                    <div className={styles['info-bubble']}>
                      <div>配送センターでの受け取り期限を過ぎると荷物が廃棄される可能性があります。</div>
                      <div>受取日のご希望がある場合「寺田金庫への連絡事項」の欄にご希望の日付を記入ください。</div>
                    </div>
                  </div>
                </>
                )}
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>住所</label>
                </div>
                <div style={{ height: '42px' }}>
                  {selectedCollectAndDeliver && (
                  <>
                    <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                      <span style={{ marginRight: '5px' }}>〒</span>
                      {' '}
                      {selectedCollectAndDeliver?.deliveryPostNo}
                    </div>
                    {selectedCollectAndDeliver?.deliveryAddress1}
                    {', '}
                    {selectedCollectAndDeliver?.deliveryAddress2}
                    {', '}
                    {selectedCollectAndDeliver?.deliveryAddress3}
                  </>
                  )}
                </div>
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>担当者名</label>
                  <span>*</span>
                </div>
                <Input
                  className={[mainStyles.input, styles.input].join(' ')}
                  value={form.deliveryName || ''}
                  onChange={(e) => setForm({ ...form, deliveryName: e })}
                />
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                <div className={styles['label-container']}>
                  <label>電話番号</label>
                  <span>*</span>
                </div>
                <Input
                  className={[mainStyles.input, styles.input].join(' ')}
                  value={form?.deliveryPhone || ''}
                  onChange={(e) => setForm({ ...form, deliveryPhone: e })}
                />
                <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
                  <span className={mainStyles['mr-1']}>※</span>
                  日中に連絡可能な連絡先をご記入ください
                </div>
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>寺田倉庫への連絡事項</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.trdContact}
                  placeholder="100文字以内で入力してください"
                  onChange={(e) => setForm({ ...form, trdContact: e.target.value })}
                />
                <div className={[mainStyles['text-danger'], mainStyles['ml-20px'], mainStyles['d-flex']].join(' ')}>
                  <span className={mainStyles['mr-1']}>※</span>
                  <div>
                    受取日のご希望がある場合、こちらへご記入ください。
                    <div>記入がない場合は上記出庫予定日の配送となります。</div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>お客様メモ</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.userMemo}
                  onChange={(e) => setForm({ ...form, userMemo: e.target.value })}
                />
              </div>
            </div>
          </div>
          <footer className={styles['body-footer']}>
            <Button color="lightGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
            <Button
              size="smaller"
              onClick={handleSendForm}
              loading={shipReserve.loading}
              disabled={!documentWarehousingListState.length}
            >
              依頼
            </Button>
          </footer>
          <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />

        </div>
)}
    />
  );
}

export default Component;
