import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ScreenModal from '../../components/ScreenModal/screenModal';
import styles from './supplierFirstUserRegistrationModal.module.css';
import mainStyles from '../main.module.css';
import { CreateFirstUserForm } from '../../services/http/supplier.api';
import Input from '../../components/Input/input';
import { useCreateFirstUserApi } from '../../hooks/api/supplier.hook';
import Button from '../../components/Button/button';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * エラーラベルのプロップスインターフェース
 */
export interface ErrorLabelType {
  message: string,
  field?: string,
}

/**
 * 取引先ファーストユーザー登録モーダルのプロップスインターフェース
 */
export interface Props {
  /** 非表示フラグ */
  hidden: boolean
  /** キャンセルハンドラー */
  handleCancel: () => void
  /** 最初のユーザー登録完了ハンドラー */
  firstUserCreated: () => void
  /** カストマーID */
  customerId: number | undefined
}

/**
 * 取引先ファーストユーザー登録モーダルのコンポーネント
*/
function Component({
  hidden, handleCancel, firstUserCreated, customerId,
}:Props) {
  // States
  const [error, setError] = useState<ErrorLabelType>({ message: '' });
  const [form, setForm] = useState<CreateFirstUserForm>({
    customerId: 0,
    email: '',
    password: '',
    tenantCode: '',
    userName: '',
  });

  // Apis
  const supplierApi = useCreateFirstUserApi();
  const { request: createLog } = useCreateLogApi();

  const resetModal = useCallback(() => {
    setForm({
      customerId: 0,
      email: '',
      password: '',
      tenantCode: '',
      userName: '',
    });
    setError({ message: '' });
  }, []);

  // Methods
  const handleCreate = useCallback(async () => {
    try {
      Validator.validateEmail(form.email);
    } catch (e) {
      setError({ field: 'メールアドレス', message: (e as Error).message });
      return;
    }
    try {
      Validator.validPasswordLength(form.password);
      Validator.validPasswordTypeChars(form.password);
      Validator.validPasswordChars(form.password);
    } catch (e) {
      setError({ field: 'パスワード', message: (e as Error).message });
      return;
    }
    try {
      createLog(LogFormName.SupplierFirstUserRegistration, LogControlName.Create);

      await supplierApi.create(form);
      firstUserCreated();
      resetModal();
    } catch (e) {
      setError({ message: (e as Error).message });
    }
  }, [firstUserCreated, form, resetModal, supplierApi]);

  useEffect(() => {
    setForm({ ...form, customerId: customerId || 0 });
  }, [customerId]);

  const isFormValid = useMemo(() => form.userName && form.email && form.password && form.customerId && form.tenantCode, [form]);

  useEffectOnce(() => {
    createLog(LogFormName.SupplierFirstUserRegistration, LogControlName.Show);
  });

  return (
    <ScreenModal
      customStyles={{
        minHeight: '580px',
        minWidth: '1000px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
      }}
    >
      <div className={styles['mainframe-body-content']}>
        <div className={styles['modal-header']}>
          <div className={styles.title}>
            取引先ファーストユーザー登録
          </div>
        </div>
        <div className={styles['modal-body']}>
          <div className={styles.label}>
            テナントコード
          </div>
          <div className={mainStyles['d-flex']}>
            <Input value={form.tenantCode || ''} className={`${styles['item-width']}`} onChange={(value) => setForm({ ...form, tenantCode: value })} />
          </div>
          <div className={styles.label}>
            ユーザー名
          </div>
          <div className={mainStyles['d-flex']}>
            <Input className={`${styles['item-width']}`} value={form.userName || ''} onChange={(value) => setForm({ ...form, userName: value })} />
          </div>
          <div className={styles.label}>
            メールアドレス
          </div>
          <div className={styles.dFlex}>
            <Input className={`${styles['item-width']}`} type="email" value={form.email || ''} onChange={(value) => setForm({ ...form, email: value })} />
          </div>
          <div className={styles.label}>
            パスワード
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
            <Input className={`${styles['item-width']}`} type="password" value={form.password || ''} onChange={(value) => setForm({ ...form, password: value })} />
            <div className={[styles.text, mainStyles['ml-5']].join(' ')}>
              ユーザー登録後、初期パスワードをユーザーへ連携してください。
            </div>
          </div>
        </div>
        <div className={styles['modal-foooter']}>
          <Button
            size="smaller"
            color="lighterGray"
            onClick={() => {
              handleCancel();
              resetModal();
            }}
          >
            キャンセル
          </Button>
          <div className="text-red">
            {error.field ? `${error.field}: ` : ''}
            {error.message}
          </div>
          <Button size="smaller" onClick={handleCreate} loading={supplierApi.loading} disabled={!isFormValid}>保存</Button>
        </div>
      </div>
    </ScreenModal>
  );
}

export default Component;
